<template lang="pug">
form-input-container(
  :label="label"
  :message="message"
  :message-type="messageType"
)
  base-input(
    type="number"
    v-model="innerValue"
    :disabled="disabled"
    :placeholder="placeholder"
    @blur:modelValue="$emit('update:modelValue', checkLimit(Number($event)))"
  )
</template>

<script lang="ts">
import type { PropType } from "vue";
import { toRefs, watch, ref, defineComponent } from "vue";

import BaseInput from "@/components/ui/base/BaseInput.vue";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";

export default defineComponent({
  name: "NumberInput",
  components: {
    BaseInput,
    FormInputContainer,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    allowNulls: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: [
    'blur:modelValue',
    'update:modelValue',
  ],
  setup(props) {

    const { modelValue } = toRefs(props);

    const innerValue = ref(modelValue.value);
    watch(modelValue, () => innerValue.value = modelValue.value)

    function checkLimit(num: number | null) {
      if (!num) {
        innerValue.value = props.allowNulls ? null : props.min;
        return props.allowNulls ? null : props.min;
      }
      if (props.min && num < props.min) {
        innerValue.value = props.min;
        return props.min;
      } else if (props.max && props.max < num) {
        innerValue.value = props.max;
        return props.max;
      } else {
        innerValue.value = num;
        return num;
      }
    }

    return {
      innerValue,
      checkLimit,
    };
  }
});
</script>

<style scoped lang="scss">
</style>
